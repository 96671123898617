import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";

import axios from "axios";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const BranchopAppInstallList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/branchop/app/install/list/ready?gcm_title=&gcm_msg=&s_mb_name=${$('#s_mb_name').val()}&o_date1=${$('#o_date1').val()}&o_date2=${$('#o_date2').val()}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&e_date1=${$('#e_date1').val()}&e_date2=${$('#e_date2').val()}&l_date1=${$('#l_date1').val()}&l_date2=${$('#l_date2').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const delIn = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 설치내역을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/app/install/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh fn_search_box">
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="이름/연락처 검색" id="s_mb_name"/>
          </div>
          <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
            <span className="p-1">가입일 :</span>
            <JDatePicker className="form-control" id="o_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="o_date2"/>
          </div>
          <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
            <span className="p-1">시작일 :</span>
            <JDatePicker className="form-control" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2"/>
          </div>
          <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
            <span className="p-1">종료일 :</span>
            <JDatePicker className="form-control" id="e_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="e_date2"/>
          </div>
          <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
            <span className="p-1">라커기간 :</span>
            <JDatePicker className="form-control" id="l_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="l_date2"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_wide_sh table_list">
          <table className="view-rounded sub_table_border auto">
            <tr>
              <th className="w_70px">No</th>
              <th className="w_70px">
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>지점명</th>
              <th>성명</th>
              <th>연락처</th>
              <th>성별</th>
              <th>연령</th>
              <th>가입일</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>라커이용기간</th>
              <th>앱설치일</th>
              <th>기기구분</th>
            </tr>
            {rets && rets.map((ret, i) => {

              //성별
              let mbSexShow;
              if (ret?.mb_sex === "M") {
                mbSexShow = "남";
              } else if (ret?.mb_sex === "F") {
                mbSexShow = "여";
              } else {
                mbSexShow = "";
              }

              //연령
              let birthShow;
              const mBirthStr = ret?.mb_birth.substring(0, 2);
              if (mBirthStr === "19") {
                birthShow = 100;
              } else {
                let mRealB;
                if (parseInt(mBirthStr) < 30) {
                  mRealB = "20" + mBirthStr;
                } else {
                  mRealB = "19" + mBirthStr;
                }
                birthShow = new Date().getFullYear() - parseInt(mRealB);
              }

              //기기구분
              let m_gubun;
              if (ret?.ios_YN === "Y") {
                m_gubun = "iphone";
              } else {
                m_gubun = "Android";
              }

              //라커기간
              let locker_date_show;
              if (ret?.locker_start_date && ret?.locker_end_date) {
                locker_date_show = `${ret?.locker_start_date} ~ ${ret?.locker_end_date}`;
              } else {
                locker_date_show = "";
              }

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      // name={`chk[${i + 1}]`}
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td>{ret?.branch_name}</td>
                  <td>{ret?.mb_name}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{mbSexShow}</td>
                  <td>{birthShow}</td>
                  <td>{(ret?.mb_open_date || '').replace(/-/g, '-').substr(0, 10)}</td>
                  <td>{ret?.start_date}</td>
                  <td>{ret?.end_date}</td>
                  <td>{locker_date_show}</td>
                  <td>{(ret?.reg_date || '').replace(/-/g, '-').substr(0, 10)}</td>
                  <td>{m_gubun}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={13}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => delIn()}>삭제</button>
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          {localStorage.getItem("USER.systemCsYN") != "Y" && pageInfo?.totalArticles > 0 && (
            (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('emp_list')}>연락처 확인</button>
            )
          )}
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default BranchopAppInstallList;
