import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";
import Constants from "../../constants/constants";

const ConfigGiftListB2B = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal1Renew, setModal1Renew] = useState(0);
  const [modal1Result, setModal1Result] = useState({});
  const [modal2Open, setModal2Open] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/branchop/gift/list/b2b/ready?date_type=${$('#date_type option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_gift_number=${$('#s_gift_number').val()}&s_mb_name=${$('#s_mb_name').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  useEffect(() => {
    loadModal1();
  }, [modal1Renew]);

  const loadModal1 = () => {
    if (!$('#make_key').val()) return;

    axios2
      .post(`/branchop/gift/list/detail/ready?make_key=${$('#make_key').val() || ''}&s_date1=${$('#modal_s_date1').val() || ''}&s_date2=${$('#modal_s_date2').val() || ''}&s_gift_number=${$('#modal_s_gift_number').val() || ''}&s_pub_YN=${$('#modal_s_pub_YN option:selected').val() || ''}&s_mb_name=${$('#modal_s_mb_name').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal1Result(result);
          setModal1Open(true);

          if (result?.ddRow?.yangdo_no === 'Y') $('#ve12').prop("checked", true);
          else $('#ve12').prop("checked", false);
          if (result?.ddRow?.delay_no === 'Y') $('#ve13').prop("checked", true);
          else $('#ve13').prop("checked", false);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit2 = () => {
    if (!$('#modal2_p_code').val()) {
      alert("이용상품을 선택해주세요");
      return;
    }

    if (!$('#modal2_s_permit').val()) {
      alert("유효기간을 선택해주세요");
      $('#modal2_s_permit').focus();
      return;
    }

    if ($('#s_cnt').val() == "" || $('#s_cnt').val() == "0") {
      alert("수량을 입력해주세요");
      $('#s_cnt').focus();
      return;
    }

    if (window.confirm('이용권을 생성하시겠습니까?')) {
      $(".loading_bg").fadeIn("fast");

      axios2.postEx("/branchop/gift/make/b2b", {
        s_year: $('#modal2_s_year option:selected').val(),
        s_month: $('#modal2_s_month option:selected').val(),
        p_code: $('#modal2_p_code option:selected').val(),
        s_permit: $('#modal2_s_permit option:selected').val(),
        s_cnt: $('#modal2_s_cnt').val(),
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);
          if (data == "err") {
            alert('오류가 발생하였습니다');
          } else {
            alert("생성되었습니다.");
            setRenew(Fn.getRenewTime());
            setModal2Open(false);
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
        });
    }
  }

  function del_data() {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 이용권 생성내역을 삭제 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/gift/list/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const modal1DelIn = () => {
    let con = $('[id^=m1chk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 이용권 생성내역을 삭제 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=m1chk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/gift/detail/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다');
            setModal1Renew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const modal1Pub = () => {
    let con = $('[id^=m1chk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (!$('#modal_pub_date').val()) {
      alert('발행일을 선택해주세요');
      return;
    }

    if (window.confirm('체크한 이용권을 발행하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=m1chk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);
      formData.append("pub_date", $('#modal_pub_date').val());

      axios2.postFormEx('/branchop/gift/publish', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('발행되었습니다');
            setModal1Renew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const down_excel = (make_key, make_month) => {
    var auth_code = $("#auth_code").val();
    if (!auth_code) {
      alert("인증코드를 입력해주세요");
      return;
    }

    var down_reason = $("#down_reason").val();
    if (!down_reason) {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `/xls/branchop/gift/detail?make_key=${make_key}&make_month=${make_month || ''}&auth_code=${auth_code}&down_reason=${down_reason}&verify=${localStorage.getItem("USER.token")}`;
    Fn.download(Constants.API_URL + url);
  };

  function popup_down_excel(make_key, make_month) {
    var auth_code = $("#popup_auth_code").val();
    if (!auth_code) {
      alert("인증코드를 입력해주세요");
      return;
    }

    var down_reason = $("#popup_down_reason").val();
    if (!down_reason) {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `/xls/branchop/gift/detail/b2b?make_key=${make_key}&make_month=${make_month || ''}&auth_code=${auth_code}&down_reason=${down_reason}&verify=${localStorage.getItem("USER.token")}`;
    Fn.download(Constants.API_URL + url);
  }

  function change_gift(type, make_key, value) {
    let real_value;
    if (value) {
      real_value = "Y";
    } else {
      real_value = "N";
    }

    $.ajax({
      type: "post",
      url: "/branchop/change/yangdo/delay",
      data: "type=" + type + "&make_key=" + make_key + "&real_value=" + real_value,
      success: function (data) {
      }
    });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="date_type">
            <option value="A.make_date">생성일</option>
            <option value="A.pub_date">발행일</option>
          </select>
        </div>
        <div className="input-group pr-1" style={{width: "auto"}}>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
          <p className="mx-2">~</p>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="이용권번호" id="s_gift_number"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="회원명" id="s_mb_name"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal2Open(true)}>이용권 생성</button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="80px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="17%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="130px"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>생성일</th>
            <th>생성년월</th>
            <th>수량</th>
            <th>이용상품</th>
            <th>발행수</th>
            <th>사용수</th>
            <th>발행/상세확인</th>
            <th>엑셀저장</th>
          </tr>
          {result?.result?.map((row, index) => (
            <tr key={row?.make_key}>
              <td>{index + 1}</td>
              <td>
                {row?.publishNum === 0 && (
                  <input type="checkbox" name={`chk[${index}]`} value={btoa(row?.make_key)} id={`achk${index}`} className="checkbox"/>
                )}
              </td>
              <td>{row?.make_date.replaceAll('-', '.').substring(2, 10)}</td>
              <td>{row?.make_month.substring(0, 2)}.{row?.make_month.substring(2, 4)}</td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>
                  {row?.cnt.toLocaleString()}
                </div>
              </td>
              <td>{row?.item_name}</td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>
                  {Fn.numberWithCommas(row?.publishNum)}
                </div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>
                  {Fn.numberWithCommas(row?.useNum)}
                </div>
              </td>
              <td>
                <input
                  type="button"
                  className="btn btn-ssm btn-outline-point"
                  value="발행/상세확인"
                  // onClick={() => pop_up(`gift_list_detail.html?make_key=${row?.make_key}`, 'gift_make_detail', 1010, 600, 'yes')}
                  onClick={() => {
                    $('#make_key').val(row?.make_key);
                    loadModal1();
                  }}
                />
              </td>
              <td>
                <input
                  type="button"
                  className="btn btn-ssm btn-outline-dark"
                  value="엑셀저장"
                  onClick={() => down_excel(row?.make_key, row?.make_month)}
                />
              </td>
            </tr>
          ))}
          {(!result?.result || result?.result?.length === 0) &&
            <tr>
              <td colSpan={10}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => del_data()}>삭제</button>
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            <div className="d-flex mx-1">
              <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운로드 사유를 입력해 주세요." style={{width: "280px"}} id="down_reason"/>
            </div>
          </div>
        </div>
      </nav>

      <input type="hidden" id="make_key"/>

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          $("input[type=text]").each(function () {
            $(this).prop("autocomplete", "one-time-code");
          });
          $("input[type=password]").each(function () {
            $(this).prop("autocomplete", "one-time-code");
          });
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01_wide p_absolute_mid_pop">
            <div className="POP_title">
              <h5><strong>이용권발행 및 세부확인</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="d-flex">
                <div className="input-group pr-1 justify-content-center align-content-center align-items-center pr-2" style={{width: "auto"}}>
                  양도불가
                  <input type="checkbox" name="yangdo_no" className="checkbox ml-1" onClick={(e) => change_gift('yangdo_no', $('#make_key').val(), e.target.checked)} id="ve12" value='Y'/>
                  <label htmlFor="ve12" className="pr-2"><span></span></label>
                  연기불가
                  <input type="checkbox" name="delay_no" className="checkbox ml-1" onClick={(e) => change_gift('delay_no', $('#make_key').val(), e.target.checked)} id="ve13" value='Y'/>
                  <label htmlFor="ve13"><span></span></label>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="input-group pr-1 justify-content-center align-content-center align-items-center" style={{width: "auto"}}>
                  <p className="p-1">발행일 :</p>
                  <JDatePicker className="form-control" aria-describedby="button-addon2" placeholder="발행시작일" id="modal_s_date1"/>
                  <p className="p-1">~</p>
                  <JDatePicker className="form-control" aria-describedby="button-addon2" placeholder="발행종료일" id="modal_s_date2"/>
                </div>

                <div className="pr-1">
                  <input type="text" className="form-control width_shorts" placeholder="이용권번호" id="modal_s_gift_number"/>
                </div>

                <div className="pr-1">
                  <select className="form-select" aria-label="Default select example" id="modal_s_pub_YN">
                    <option value="">발행여부</option>
                    <option value="N">미발행</option>
                    <option value="Y">발행완료</option>
                  </select>
                </div>

                <div className="pr-1">
                  <input type="text" className="form-control width_shorts" placeholder="회원명" id="modal_s_mb_name"/>
                </div>

                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setModal1Renew(Fn.getRenewTime())}>검색</button>
                </div>

                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => popup_down_excel($('#make_key').val())}>엑셀저장</button>
                </div>
              </div>

              <div className="text-center mt-3">
                {/* 회원목록 */}
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="60px"/>
                    <col width="70px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="180px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>
                      <input type="checkbox" onClick={(e) => {
                        if ($(e.target).prop("checked")) $('[id^=m1chk]').prop("checked", true);
                        else $('[id^=m1chk]').prop("checked", false);
                      }}/>
                    </th>
                    <th>발행일</th>
                    <th>유효기간</th>
                    <th>이용권번호</th>
                    <th>지점명</th>
                    <th>회원번호</th>
                    <th>회원명</th>
                    <th>종료일</th>
                    <th>사용일</th>
                  </tr>
                  {modal1Result?.result?.map((row, i) => {
                    const count = i + 1;
                    const base_seq = btoa(row?.seq);

                    const mb_info_split = row?.mb_info?.split("||");

                    return (
                      <tr key={count}>
                        <td>{count}</td>
                        <td>
                          {!row.pub_date ? (
                            <React.Fragment>
                              <input type="checkbox" name={`m1chk[${count}]`} value={base_seq} id={`m1chk${count}`} className="checkbox_sm"/>
                              <label htmlFor={`m1chk${count}`}><span></span></label>
                            </React.Fragment>
                          ) : null}
                        </td>
                        <td>{row?.pub_date ? row?.pub_date?.replace(/-/g, '.')?.substring(2, 10) : null}</td>
                        <td>{row?.permit_date ? row?.permit_date?.replace(/-/g, '.')?.substring(2, 10) : null}</td>
                        <td>{`${row?.gift_number?.substring(0, 4)}-${row?.gift_number?.substring(4, 8)}-${row?.gift_number?.substring(8, 12)}`}</td>
                        <td>{row?.branch_name}</td>
                        <td>{mb_info_split?.[0]}</td>
                        <td>{mb_info_split?.[1]}</td>
                        <td>{row?.end_date}</td>
                        <td>{row?.use_date ? row?.use_date?.replace(/-/g, '.')?.substring(2, 10) : null}</td>
                      </tr>
                    );
                  })}
                  {(!modal1Result?.result || modal1Result?.result?.length === 0) &&
                    <tr>
                      <td colSpan={10}>※ 등록된 data가 없습니다.</td>
                    </tr>
                  }
                </table>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <div className="d-flex">
                <JDatePicker className="form-control" aria-describedby="button-addon2" style={{width: "130px"}} id="modal_pub_date"/>
                <button type="button" className="btn btn-sm btn-outline-point px-2" onClick={() => modal1Pub()}>발행</button>
              </div>
              <button type="button" className="btn btn-sm btn-outline-dark px-2 mx-2" onClick={() => modal1DelIn()}>삭제</button>
              <div className="d-flex">
                <ChkAuthNum type={"locker_info_change"} prefix="popup_"/>
              </div>
              <div className="d-flex mx-1">
                <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운로드 사유 입력" style={{width: "150px"}} id="popup_down_reason"/>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          axios2
            .post(`/branchop/gift/make/b2b/ready`)
            .then((response) => {
              const {code, message, result, pageInfo} = response?.data;
              if (code === 200) {
                $('#modal2_s_year').val(Fn.parseInt(Fn.getCurrentDate().split('-')[0]));
                $('#modal2_s_month').val(Fn.parseInt(Fn.getCurrentDate().split('-')[1]));

                if ($('#modal2_p_code').html() === '') {
                  let optionsHTML = '<option value="">::선택</option>';
                  result?.d?.forEach((d_row) => {
                    optionsHTML += `<option value="${d_row.it_id}">${d_row.it_name}</option>`;
                  });
                  $('#modal2_p_code').html(optionsHTML);
                }
              }
            })
            .catch((error) => {
              if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            });
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop">
            <div className="POP_title">
              <h5><strong>이용권 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="120px"/>
                  <col width="120px"/>
                  <col width="*"/>
                  <col width="120px"/>
                  <col width="120px"/>
                </colgroup>

                <tr>
                  <th>년</th>
                  <th>월</th>
                  <th>이용상품</th>
                  <th>유효기간</th>
                  <th>수량</th>
                </tr>
                <tr>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="modal2_s_year">
                      {Array.from({length: 2}, (_, index) => new Date().getFullYear() - 0 + index).map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="modal2_s_month">
                      {[...Array(12)].map((_, index) => {
                        const monthNumber = index + 1;
                        const monthString = `${monthNumber}`;
                        return (
                          <option key={monthString} value={monthString}>{monthString}</option>
                        );
                      })}
                    </select>
                  </td>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="modal2_p_code">
                    </select>
                  </td>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="modal2_s_permit">
                      <option value="">::선택::</option>
                      <option value="1">1개월</option>
                      <option value="2">2개월</option>
                      <option value="3">3개월</option>
                      <option value="4">4개월</option>
                      <option value="5">5개월</option>
                      <option value="6">6개월</option>
                      <option value="12">12개월</option>
                      <option value="18">18개월</option>
                      <option value="24">24개월</option>
                      <option value="36">36개월</option>
                      <option value="48">48개월</option>
                      <option value="60">60개월</option>
                    </select>
                  </td>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" id="modal2_s_cnt"/></td>
                </tr>
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit2()}>생성</button>
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModal2Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default ConfigGiftListB2B;
